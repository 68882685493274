import React from "react"

export default function SecurityIcon({
  fill = "#018762"
}) {
  return (
    <svg
      width="22"
      height="28"
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0007 0.666992L0.333984 4.66699V12.787C0.333984 19.5203 4.88065 25.8003 11.0007 27.3337C17.1207 25.8003 21.6673 19.5203 21.6673 12.787V4.66699L11.0007 0.666992ZM19.0006 12.787C19.0006 18.1203 15.6007 23.0537 11.0007 24.5603C6.40065 23.0537 3.00065 18.1337 3.00065 12.787V6.52033L11.0007 3.52033L19.0006 6.52033V12.787Z"
        fill={fill}
      />
    </svg>
  )
}
