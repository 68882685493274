import React, { useEffect, useMemo, useState } from "react"
import { Heading } from "../heading"
import { Spacing } from "../spacing"
import Button from "../../profissionaisSaude/Inputs/Button"
import { TextInput } from "../../profissionaisSaude/Inputs/InputText"
import CheckPasswords from "../../profissionaisSaude/Inputs/CheckPasswords"
import { Buttons } from "../buttons"

const ClosedChangePassword = ({ onClick }) => {
  return (
    <>
      <Heading title="Senha" subtitle="**********" strong tiny />

      <Spacing size={2} />

      <Button
        title="Alterar senha"
        shadowy
        onClick={() => {
          onClick?.()
        }}
        noLink
        maxWidth={15}
      />
    </>
  )
}

const OpenChangePassword = ({ onCancel, onSubmitted }) => {
  const [isValid, setIsValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isNewValid, setIsNewValid] = useState(false)
  const [currentPassword, setCurrentPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("")
  const [currentPasswordError, setCurrentPasswordError] = useState("")

  const validate = password => {
    setCurrentPasswordError("")

    if (password.length < 8) {
      setCurrentPasswordError("A senha atual deve ser fornecida.")
      return
    }

    setIsValid(isNewValid)
  }

  const onSubmit = useMemo(
    () =>
      isNewValid
        ? () => {
            setLoading(true)
            setTimeout(() => {
              setLoading(false)
              onSubmitted?.()
            }, 800)
          }
        : () => validate(currentPassword),
    [isNewValid, currentPassword]
  )

  useEffect(
    () => validate(currentPassword),
    [currentPassword, newPasswordConfirmation, isValid]
  )

  return (
    <>
      <Heading title="Alterar senha" strong tiny />

      <Spacing size={2} />

      <TextInput
        onChange={event => setCurrentPassword(event.target.value)}
        value={currentPassword}
        placeholder="Senha atual"
        label="Senha atual *"
        error={currentPasswordError}
        isPassword
        large={true}
      />

      <Spacing size={1} />

      <TextInput
        onChange={event => setNewPassword(event.target.value)}
        value={newPassword}
        placeholder="Nova senha"
        label="Nova senha *"
        isPassword
        large={true}
      />

      <Spacing size={1} />

      <TextInput
        onChange={event => setNewPasswordConfirmation(event.target.value)}
        value={newPasswordConfirmation}
        placeholder="Repita a senha"
        label="Confirme a nova senha *"
        isPassword
        large={true}
      />

      <Spacing size={1.5} />

      <CheckPasswords
        passwords={{
          password: newPassword,
          passwordConfirmation: newPasswordConfirmation,
          isPasswordsMatchs: newPasswordConfirmation === newPassword,
          isActive: true,
        }}
        onValid={setIsNewValid}
        invalidateFirst
      />

      <Spacing size={2} />

      <Buttons>
        <Button
          title="Cancelar"
          variant="outlined"
          onClick={onCancel}
          noLink
          shadowy
          maxWidth={15}
        />

        <Button
          title="Salvar senha"
          onClick={onSubmit}
          loading={loading}
          noLink
          shadowy
          maxWidth={15}
        />
      </Buttons>
    </>
  )
}

export const ChangePasswordSection = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Heading
        title="Segurança"
        titleSizing="extra-large"
        subtitle="Gerencie os ajustes da sua conta e a deixe mais protegida."
      />

      <Spacing size={2} />

      {open ? (
        <OpenChangePassword
          onCancel={() => setOpen(false)}
          onSubmitted={() => setOpen(false)}
        />
      ) : (
        <ClosedChangePassword onClick={() => setOpen(true)} />
      )}

      <Spacing size={2} />
    </>
  )
}
