import React from "react";
import { Label, Wrapper } from "./styles";
import { Info } from "../../../assets/icons/Info";

export const InfoItem = ({ label }) => {
  return (
    <Wrapper>
      <Info svgTitle={`Dado fornecido: ${label}`} />

      <Label>
        {label}
      </Label>
    </Wrapper>
  )
}