import React, { useEffect, useMemo } from "react"
import { useState } from "react"
import { Spacing } from "../spacing"
import { Heading } from "../heading"
import { Buttons } from "../buttons"
import Button from "../../profissionaisSaude/Inputs/Button"
import { TextInput } from "../../profissionaisSaude/Inputs/InputText"
import CheckEmails from "../../profissionaisSaude/Inputs/CheckEmails"

export const ClosedChangeEmail = ({ onClick }) => {
  return (
    <>
      <Heading title="E-mail" subtitle="angelica.sa@email.com" strong tiny />

      <Spacing size={2} />

      <Button
        title="Editar dados"
        variant="outlined"
        shadowy
        onClick={onClick}
        noLink
        maxWidth={15}
      />
    </>
  )
}

export const OpenChangeEmail = ({ onSubmitted, onCancel }) => {
  const [error, setError] = useState("")
  const [isValid, setIsValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isNewValid, setIsNewValid] = useState(false)
  const [currentEmail, setCurrentEmail] = useState("")
  const [newEmail, setNewEmail] = useState("")
  const [newEmailConfirmation, setNewEmailConfirmation] = useState("")

  const validateEmail = () => {
    setError("")

    if (currentEmail.trim().length <= 0) {
      setError("Seu e-mail atual deve ser fornecido")
      return
    }

    if (currentEmail.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i) === null) {
      setError("Insira um endereço de e-mail válido.")
      return
    }

    setIsValid(true)
  }

  const onSubmit = useMemo(
    () =>
      isNewValid
        ? () => {
            setLoading(true)
            setTimeout(() => {
              setLoading(false)
              onSubmitted?.()
            }, 800)
          }
        : () => validateEmail(),
    [isNewValid, currentEmail]
  )

  useEffect(
    () => validateEmail(currentEmail),
    [currentEmail, isValid, newEmailConfirmation]
  )

  return (
    <>
      <Heading title="Alterar e-mail" strong tiny />

      <Spacing size={2} />

      <TextInput
        onChange={event => setCurrentEmail(event.target.value)}
        value={currentEmail}
        placeholder="E-mail atual"
        label="E-mail atual *"
        error={error}
        large={true}
      />
      <Spacing size={1} />

      <TextInput
        onChange={event => setNewEmail(event.target.value)}
        value={newEmail}
        placeholder="Novo e-mail"
        label="Novo e-mail *"
        large={true}
      />

      <Spacing size={1} />

      <TextInput
        onChange={event => setNewEmailConfirmation(event.target.value)}
        value={newEmailConfirmation}
        placeholder="Repita e-mail"
        label="Confirme o novo e-mail *"
        large={true}
      />

      <Spacing size={1.5} />

      <CheckEmails
        emailsConfig={{
          email: newEmail,
          emailConfirmation: newEmailConfirmation,
          isEmailsMatchs: newEmailConfirmation === newEmail,
          isActive: true,
        }}
        onValid={setIsNewValid}
        invalidateFirst
      />

      <Spacing size={2} />

      <Buttons>
        <Button
          title="Cancelar"
          variant="outlined"
          onClick={onCancel}
          noLink
          shadowy
          maxWidth={15}
        />

        <Button
          title="Salvar e-mail"
          onClick={onSubmit}
          loading={loading}
          noLink
          shadowy
          maxWidth={15}
        />
      </Buttons>
    </>
  )
}

export const ChangeEmailSection = () => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <Spacing size={2} />

      {open ? (
        <OpenChangeEmail
          onCancel={() => setOpen(false)}
          onSubmitted={() => setOpen(false)}
        />
      ) : (
        <ClosedChangeEmail onClick={() => setOpen(true)} />
      )}
    </>
  )
}
