import React, { useMemo } from "react"
import styled from "styled-components"
import theme from "../../../styles/theme"

export const TitleSizing = {
  ExtraLarge: "extra-large",
  Large: "large",
  Medium: "medium",
  Small: "small",
  SuperSmall: "extra-small",
}

export const TitleSizingMapping = {
  "extra-large": "2rem",
  large: "1.5rem",
  medium: "1.5rem",
  small: "1.125rem",
  "extra-small": "1rem",
}

const PrimaryTitle = styled.h2`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: ${({ size }) => size ?? TitleSizingMapping.Medium};
  line-height: 120%;
  text-align: left;
  color: ${theme.palette.grayscale.main};
`

const SecondaryTitle = styled.h3`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: ${({ size }) => size ?? TitleSizingMapping.Medium};
  line-height: 120%;
  text-align: left;
  color: ${theme.palette.grayscale.main};
`

const Subtitle = styled.p`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: ${({ size }) => size ?? TitleSizingMapping["extra-small"]};
  line-height: 150%;
  text-align: left;
  color: ${theme.palette.grayscale.main};
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  row-gap: ${({ gap = 1 }) => `${gap}rem`};
`

export const Heading = ({
  tiny,
  titleSizing,
  title,
  subtitle,
  subtitleSizing,
  gap = 1,
  strong,
}) => {
  const titleSize = useMemo(
    () => TitleSizingMapping[titleSizing] ?? TitleSizingMapping.Medium,
    [titleSizing]
  )
  const subtitleSize = useMemo(
    () => TitleSizingMapping[subtitleSizing] ?? TitleSizingMapping.Medium,
    [subtitleSizing]
  )
  const MainHeading = useMemo(() => {
    if (tiny)
      return ({ children, size }) => (
        <SecondaryTitle size={size}>{children}</SecondaryTitle>
      )

    return ({ children, size }) => (
      <PrimaryTitle size={size}>{children}</PrimaryTitle>
    )
  }, [tiny])
  const StrongOrNot = useMemo(
    () =>
      strong
        ? ({ children }) => <b>{children}</b>
        : ({ children }) => <>{children}</>,
    [strong]
  )

  return (
    <Wrapper gap={gap}>
      <MainHeading size={titleSize}>
        <StrongOrNot>{title}</StrongOrNot>
      </MainHeading>

      {subtitle ? (
        <Subtitle size={subtitleSize}>
          <StrongOrNot>{subtitle}</StrongOrNot>
        </Subtitle>
      ) : (
        <></>
      )}
    </Wrapper>
  )
}
