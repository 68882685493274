import styled from "styled-components"

export const Wrapper = styled.li`
  list-style-type: none;
  padding: 0;
  margin: 0 0 0 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1rem;
`

export const Label = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 150%;
  text-align: center;
`
