import styled from "styled-components"

export const Container = styled.div `
  display: flex;
  flex-direction: column;
  gap: ${({theme}) => theme.spacing['2xs']};
  font-size: ${({theme}) => theme.fontSize.fontSize.fontSizeXS};
`

export const GreySpan = styled.span`
  color: #515151;
`
export const ListOption = styled.li`
  display: flex;
  align-items: center;
  grid-gap: 0.5rem;
  height: 1.875em;
  color: #515151;

  &::before {
    content: counter(bullet, disc);
  }

  &:has(span + span) {
    color: ${({ valid }) => (valid ? "green" : "red")};

    &::before {
      content: none;
    }
  }
`
