import styled from "styled-components"
import theme from "../../../styles/theme"

export const Wrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 2rem;
`

export const AvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 106px;
  height: 110px;
  border-radius: 50%;
  border: 1.5px solid ${theme.colors.secondary.health_green};
  box-sizing: border-box;
  padding: 0.25rem;
  overflow: hidden;

  .img-avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
  }
`

export const Avatar = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-image: url(${({ photo }) => photo});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  background-color: ${theme.colors.secondary.health_green};
`

export const DetailsWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 0.5rem;
`

export const TitleAndNameLabel = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 120%;
  color: ${theme.palette.grayscale.main};
`

export const RoleAndRegistryCodeLabel = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 150%;
  color: ${theme.palette.grayscale.variations[1]};

  .code {
    text-transform: uppercase;
  }
`

export const TagsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1.25rem;
`
