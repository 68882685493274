import React from "react"
import Footer from "../../components/profissionaisSaude/Footer"
import Header from "../../components/profissionaisSaude/Header"
import { ProfileCard } from "../../components/profissionaisSegurancaPrivacidade/profile-card"
import styled from "styled-components"
import theme from "../../styles/theme"
import { useState } from "react"
import SecurityIcon from "../../assets/icons/SecurityIcon"
import PrivacyIcon from "../../assets/icons/PrivacyIcon"
import { TabContent } from "../../components/profissionaisSegurancaPrivacidade/tab-content"
import { Heading } from "../../components/profissionaisSegurancaPrivacidade/heading"
import { Divider } from "../../components/profissionaisSegurancaPrivacidade/divider"
import Button from "../../components/profissionaisSaude/Inputs/Button"
import { Spacing } from "../../components/profissionaisSegurancaPrivacidade/spacing"
import { ChangePasswordSection } from "../../components/profissionaisSegurancaPrivacidade/change-password"
import { ChangeEmailSection } from "../../components/profissionaisSegurancaPrivacidade/change-email"
import { InfoItem } from "../../components/profissionaisSegurancaPrivacidade/info-item"
import { Link } from "gatsby"
import { Switch } from "../../components/profissionaisSegurancaPrivacidade/switch"

const Content = styled.section`
  width: 100%;
  max-width: 68.75rem;
  min-height: calc(100vh - 13.25rem);
  display: flex;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 3rem 0 4rem;
  gap: 1.375rem;

  @media screen and (max-width: 1020px) {
    padding: 1rem 2.5rem;
  }

  .heading {
    margin-bottom: 2rem;
  }
`

const TabList = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 0.875rem;
`

const TabListItem = styled.li`
  padding: 0;
  margin: 0;
  display: block;
`

const TabButton = styled.button`
  padding: 0.5rem 0.75rem 0.5rem 0.5rem;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: 0.875rem;
  border-bottom: 4px solid transparent;

  &.current {
    border-color: ${theme.colors.secondary.health_green};

    span {
      color: ${theme.colors.secondary.health_green} !important;
    }
  }

  span {
    color: ${theme.palette.grayscale.variations[1]};
  }
`

const TabButtonLabel = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
`

const Tabs = {
  Privacy: "privacy",
  Security: "security",
}

const TabContents = styled.div`
  width: 100%;
`
const InfoList = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  row-gap: 0.5rem;
`

const PrivacyPolice = styled(Link)`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 150%;
  text-decoration-line: underline;
  color: ${theme.colors.secondary.health_green};
  text-align: right;
  display: block;
`

export default function MinhaConta() {
  const [tab, setTab] = useState(Tabs.Security)
  const [visible, setVisible] = useState(true)
  const [newsletter, setNewsletter] = useState(false)
  const [openAcc, setOpenAcc] = useState(true)
  const handleOnClickSecurity = () => setTab(Tabs.Security)
  const handleOnClickPrivacy = () => setTab(Tabs.Privacy)
  const handleAccStatus = () => setOpenAcc(!openAcc)

  return (
    <>
      <Header />

      <Content>
        <div className="heading">
          <ProfileCard
            ethnicity="black"
            gender="trans-woman"
            name="Angélica de Sá"
            orientation="lesbian"
            photo="profile_image_1.png"
            registryCode="CRM SP 0304"
            role="Dermatologista"
            title="Dra."
          />
        </div>

        <TabList>
          <TabListItem>
            <TabButton
              className={tab === Tabs.Security ? "current" : undefined}
              onClick={handleOnClickSecurity}
            >
              <SecurityIcon
                fill={
                  tab === Tabs.Security
                    ? theme.colors.secondary.health_green
                    : theme.palette.grayscale.variations[1]
                }
              />

              <TabButtonLabel>Segurança</TabButtonLabel>
            </TabButton>
          </TabListItem>

          <TabListItem>
            <TabButton
              className={tab === Tabs.Privacy ? "current" : undefined}
              onClick={handleOnClickPrivacy}
            >
              <PrivacyIcon
                fill={
                  tab === Tabs.Privacy
                    ? theme.colors.secondary.health_green
                    : theme.palette.grayscale.variations[1]
                }
              />
              <TabButtonLabel>Privacidade</TabButtonLabel>
            </TabButton>
          </TabListItem>
        </TabList>

        <TabContents>
          <TabContent
            open={tab === Tabs.Security}
            halfScreen="60%"
            padding="2rem 0 4rem 0"
          >
            <ChangePasswordSection />

            <ChangeEmailSection />
          </TabContent>

          <TabContent open={tab === Tabs.Privacy}>
            <Heading
              title="Privacidade"
              titleSizing="extra-large"
              subtitle="Veja seus ajustes de privacidade e gerencie as informações que você compartilha com a Lacrei Saúde."
            />

            <Spacing size={1} />

            <Heading
              title="O que  você compartilha com a Lacrei Saúde"
              titleSizing="extra-small"
              subtitle="A pessoa usuária poderá ter acesso as suas seguintes informações:"
            />

            <Spacing size={1} />

            <InfoList>
              <InfoItem label="Foto" />
              <InfoItem label="Nome civil ou social" />
              <InfoItem label="Pronome" />
              <InfoItem label="Etnia" />
              <InfoItem label="Identidade de gênero" />
              <InfoItem label="Sexualidade" />
              <InfoItem label="Informações sobre deficiência(s)" />
              <InfoItem label="Profissão" />
              <InfoItem label="Especialidade" />
              <InfoItem label="Número do Conselho de Classe" />
              <InfoItem label="Prefixo do Conselho de Classe" />
              <InfoItem label="Registro de Qualificação de Especialista (RQE)" />
            </InfoList>

            <Spacing size={2} />

            <Divider />

            <Spacing size={2} />

            <Heading
              title="Seus dados"
              titleSizing="large"
              subtitle="Solicitar as informações sobre os meus dados:"
            />

            <Spacing size={2} />

            <Button
              variant="outlined"
              shadowy
              noLink
              title="Solicitar meus dados"
              maxWidth={15.9375}
            />

            <Spacing size={2} />

            <Divider />

            <Spacing size={2} />

            <Heading title="Conta" titleSizing="large" />

            <Spacing size={2} />

            <Heading
              title="Desativar"
              titleSizing="medium"
              subtitle="Deseja desativar temporariamente sua conta profissional da Saúde?"
            />

            <Spacing size={2} />

            <Button
              title="Desativar minha conta"
              variant="sensitive"
              shadowy
              maxWidth={15.9375}
              noLink
              onClick={handleAccStatus}
              disabled={openAcc ? false : true}
            />

            <Spacing size={2} />

            <Heading
              title="Reativar"
              titleSizing="medium"
              subtitle="Atualmente sua conta encontra-se temporariamente desativada. Deseja reativar sua conta profissional da Saúde?"
            />

            <Spacing size={2} />

            <Button
              title="Reativar minha conta"
              disabled={openAcc ? true : false}
              variant="sensitive"
              shadowy
              maxWidth={15.9375}
              link="/seguranca/reativar-conta"
              onClick={handleAccStatus}
            />

            <Spacing size={2} />

            <Heading
              title="Apagar"
              titleSizing="medium"
              subtitle="Deseja apagar definitivamente sua conta profissional da Saúde? Ao fazer isso todos os seus dados serão apagados e sua conta não poderá ser recuperada."
            />

            <Spacing size={2} />

            <Button
              title="Apagar minha conta"
              variant="sensitive"
              shadowy
              maxWidth={15.9375}
            />

            <Spacing size={2} />

            <Divider />

            <Spacing size={2} />

            <Heading
              title="Minha conta"
              titleSizing="large"
              subtitle="Perfil"
              subtitleSizing="small"
              strong
              gap={2}
            />

            <Spacing size={2} />

            <Switch
              label="Deseja tornar visível o seu perfil profissional da Saúde?"
              checked={visible}
              onChange={() => setVisible(v => !v)}
              labelPosition="after"
            />

            <Spacing size={2} />

            <Divider />

            <Spacing size={2} />

            <Heading
              title="Mensagens da Lacrei"
              titleSizing="large"
              subtitle="A Lacrei está sempre trabalhando para construir uma plataforma com soluções que tenham relevância na sua vida. Ative as mensagens para receber todas as novidades."
              subtitleSizing="small"
            />

            <Heading
              subtitle="Recebimento de e-mails desativado"
              subtitleSizing="small"
            />

            <Spacing size={2} />

            <Switch
              label="Desejo receber e-mails com as novidades da Lacrei"
              checked={newsletter}
              direction="flex-start"
              onChange={() => setNewsletter(v => !v)}
            />

            <Spacing size={2} />

            <Divider />

            <Spacing size={2} />

            <PrivacyPolice to="/">Politica de uso e privacidade</PrivacyPolice>

            <Spacing size={4} />
          </TabContent>
        </TabContents>
      </Content>

      <Footer />
    </>
  )
}
