import React, { useEffect, useState } from "react"
import { ListOption, GreySpan } from "./styles"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"

const checkIfIsEmailValid = email => {
  return email.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/i) === null
}

const CheckEmails = ({ emailsConfig, onValid }) => {
  const [visible, setVisible] = useState(false)
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    const { email, emailConfirmation, isActive, isEmailsMatchs } = emailsConfig
    setVisible(isActive)
    if (email.length === 0) return

    setIsValid(
      !checkIfIsEmailValid(email) && !checkIfIsEmailValid(emailConfirmation)
    )

    if (isValid && isEmailsMatchs) {
      onValid(true)
    } else {
      onValid(false)
    }
  }, [emailsConfig])

  const checkField = fieldValue => {
    if (emailsConfig.email.length > 0)
      return fieldValue ? (
        <span>
          <CheckIcon />
        </span>
      ) : (
        <span>
          <CloseIcon />
        </span>
      )
  }

  return (
    <>
      {visible && (
        <>
          <GreySpan>Os emails devem ser:</GreySpan>

          <ul>
            <ListOption valid={isValid}>
              {checkField(isValid)}
              <span>Válidos</span>
            </ListOption>

            <ListOption valid={emailsConfig.isEmailsMatchs}>
              {checkField(emailsConfig.isEmailsMatchs)}
              <span>Iguais</span>
            </ListOption>
          </ul>
        </>
      )}
    </>
  )
}

export default CheckEmails
