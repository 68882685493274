import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/**
Componente de imagem para renderizar imagens locais ou da API
@param {boolean} [apiImage=false] - Indica se a imagem é da API (o valor padrão é false)
@param {string} imagePath - Caminho da imagem, no caso de imagem da API, ou apenas nome e extensão da imagem, no caso de imagem local (obrigatório)
@param {string} imageAlt - Texto alternativo para a imagem (obrigatório)
@param {string} [imageClass=""] - Classe CSS para a imagem (opcional)
@param {string} [imageWidth=""] - Largura da imagem (opcional ou obrigatório caso não seja definida largura com imageClass)
@param {string} [imageHeight=""] - Altura da imagem (opcional ou obrigatório caso não seja definida altura com imageClass)
@param {string} [imageFit="contain"] - Define como a imagem se ajusta à área de visualização
@returns {JSX.Element} Elemento React para renderizar a imagem

@example
<Image 
    apiImage
    imagePath="/caminho-imagem/imagem.png"
    imageAlt="Texto alternativo da imagem"
    imageClass="classe-imagem"
    imageFit="cover"
/>

@example
<Image 
    imagePath="imagem.jpg"
    imageAlt="Texto alternativo da imagem"
    imageWidth="200px"
    imageHeight="200px"
/>
*/

export default function Image({
    apiImage = false,
    imagePath,
    imageAlt,
    imageClass = "",
    imageWidth = "",
    imageHeight = "",
    imageFit = "contain" }) {

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    apiUrl
                }
            }
            allFile(filter: {extension: {regex: "/(jpg)|(jpeg)|(png)/"}}) {
                edges {
                    node {
                        relativePath
                        childImageSharp {
                            fluid(maxWidth: 2400) {
                            ...GatsbyImageSharpFluid
                            }
                        } 
                    }
                }
            }
        }
    `)

    const image = apiImage ? `${data.site.siteMetadata.apiUrl}/${imagePath}` : data?.allFile?.edges?.
        find(edge => edge.node.relativePath === imagePath)?.
        node.childImageSharp.fluid

    if (apiImage) {
        return (
            <Img
                fluid={{
                    src: image,
                    sizes: "(max-width: 1200px) 100vw, 1200px",
                    aspectRatio: 1,
                    // Defina outras propriedades opcionais de acordo com a API
                }}
                alt={imageAlt}
                className={imageClass}
                style={{ width: imageWidth, height: imageHeight }}
            />
        )
    } else {
        if (!image) {
            return <Img fluid={data?.allFile?.edges?.find(edge => edge.node.relativePath === "image_not_found.jpg")?.node.childImageSharp.fluid}
                alt="Imagem não encontrada"
                className={imageClass}
                style={{ width: imageWidth, height: imageHeight }}
                imgStyle={{ objectFit: imageFit }} />
        } else {
            return <Img fluid={image}
                alt={imageAlt}
                className={imageClass}
                style={{ width: imageWidth, height: imageHeight }}
                imgStyle={{ objectFit: imageFit }} />
        }
    }
}