import React from "react"
import { Label, Wrapper, IconContainer } from "./styles"

/**
 * @typedef {Object} ProfileTagProps
 * @property {ReactElement} Icon
 * @property {string} label
 */

/**
 * @param {ProfileTagProps}
 */
export function ProfileTag({ Icon, label }) {
  return (
    <Wrapper>
      <IconContainer>
        {Icon}
      </IconContainer>
      <Label>{label}</Label>
    </Wrapper>
  )
}
