import React from "react"
import { useCallback, useMemo } from "react"

import { Ball, Label, Track, Wrapper } from "./styles"
import { useRandomIds } from "../../../functions/useRandomIds"
import Check from "@mui/icons-material/Check"

export function Switch({
  label,
  direction = "space-between",
  labelPosition = "before",
  checked = false,
  onChange,
}) {
  const getId = useRandomIds()

  const handleOnClick = useCallback(() => onChange?.(), [])

  return (
    <Wrapper direction={direction}>
      {labelPosition === "after" ? (
        <Label htmlFor={getId("switch")}>{label}</Label>
      ) : (
        <></>
      )}

      <Track
        aria-checked={checked}
        id={getId("switch")}
        role="checkbox"
        onClick={handleOnClick}
      >
        <Ball>
          <Check htmlColor="#018762" />
        </Ball>
      </Track>

      {labelPosition === "before" ? (
        <Label htmlFor={getId("switch")}>{label}</Label>
      ) : (
        <></>
      )}
    </Wrapper>
  )
}
