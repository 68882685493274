import React from "react"
import { Content } from "./styles"

export const TabContent = ({
  open,
  id,
  children,
  padding,
  halfScreen = "100%",
  ...props
}) => {
  return open ? (
    <Content id={id} padding={padding} halfScreen={halfScreen} {...props}>
      {children}
    </Content>
  ) : (
    <></>
  )
}
