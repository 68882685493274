import styled from "styled-components"
import theme from "../../../styles/theme"

export const Line = styled.div`
  width: 100%;
  height: ${({ thickness = 1 }) => `${thickness}rem`};
  background-color: ${theme.colors.gray.disable};
  display: block;
  user-select: none;
  pointer-events: none;
`
