import styled from "styled-components"
import theme from "../../../styles/theme"

export const Content = styled.div`
  width: ${({ halfScreen }) => halfScreen ?? "100%"};
  padding: ${({ padding }) => padding ?? "inherit"};

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
`
