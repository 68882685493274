import styled from "styled-components"
import theme from "../../../styles/theme"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0.75rem;
  padding: 1rem 0;
`

export const Label = styled.span`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: left;
  color: ${theme.palette.black};
`

export const IconContainer = styled.div `
  width: 24px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    object-fit: fill;
    width: 100%;
    height: 100%;
  }
`
