import React, { useEffect, useState } from "react"
import { ListOption, GreySpan, Container } from "./styles"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"

const CheckPasswords = ({ passwords, onValid }) => {
  const [visible, setVisible] = useState(false)
  const [passwordLength, setPasswordLength] = useState(0)
  const [validLength, setValidLength] = useState(false)
  const [hasNumber, setHasNumber] = useState(false)
  const [upperCase, setUpperCase] = useState(false)
  const [lowerCase, setLowerCase] = useState(false)
  const [specialChar, setSpecialChar] = useState(false)
  const [match, setMatch] = useState(false)

  useEffect(() => {
    const { password, passwordConfirmation, isActive, isPasswordsMatchs } =
      passwords
    setPasswordLength(password.length)
    setVisible(isActive)
    if (password.length === 0) return
    setValidLength(password.length >= 8 ? true : false)
    setUpperCase(password.toLowerCase() !== password)
    setLowerCase(password.toUpperCase() !== password)
    setHasNumber(/\d/.test(password))
    setSpecialChar(/[`!@#$%^&*()_+\-=\]{};':"\\|,.<>?~]/.test(password))
    if (passwordConfirmation) {
      setMatch(password === passwordConfirmation)
    }

    if (
      validLength &&
      hasNumber &&
      upperCase &&
      lowerCase &&
      specialChar &&
      (isPasswordsMatchs || match)
    ) {
      onValid?.(true)
    } else {
      onValid?.(false)
    }
  }, [passwords])

  const checkField = fieldValue => {
    if (passwordLength > 0)
      return fieldValue ? (
        <span>
          <CheckIcon />
        </span>
      ) : (
        <span>
          <CloseIcon />
        </span>
      )
  }

  return (
    <>
      {visible && (
        <Container>
          <GreySpan>A senha deve conter no mínimo:</GreySpan>
          <ul>
            <ListOption valid={validLength}>
              {checkField(validLength)}
              <span>8 caracteres</span>
            </ListOption>

            <ListOption valid={upperCase}>
              {checkField(upperCase)}
              <span>Letra maiúscula</span>
            </ListOption>

            <ListOption valid={lowerCase}>
              {checkField(lowerCase)}
              <span>Letra minúscula</span>
            </ListOption>

            <ListOption valid={hasNumber}>
              {checkField(hasNumber)} <span>Número</span>
            </ListOption>

            <ListOption valid={specialChar}>
              {checkField(specialChar)}
              <span>Caractere especial (ex: #!*-_&)</span>
            </ListOption>

            <ListOption valid={match}>
              {checkField(match)}
              <span>As senhas iguais</span>
            </ListOption>
          </ul>
        </Container>
      )}
    </>
  )
}

export default CheckPasswords
