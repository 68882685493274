import React from "react"

export const Info = ({ fill = "#0063F7", svgTitle }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {svgTitle && <title>{svgTitle}</title>}
      <path
        d="M9.98286 6.5829C10.4124 6.5829 10.7606 6.23468 10.7606 5.80512C10.7606 5.37557 10.4124 5.02734 9.98286 5.02734C9.5533 5.02734 9.20508 5.37557 9.20508 5.80512C9.20508 6.23468 9.5533 6.5829 9.98286 6.5829Z"
        fill={fill}
      />
      <path
        d="M11.6662 13.8886H10.5551V7.83301H8.88845C8.74111 7.83301 8.5998 7.89154 8.49562 7.99573C8.39143 8.09991 8.3329 8.24122 8.3329 8.38856C8.3329 8.53591 8.39143 8.67721 8.49562 8.7814C8.5998 8.88559 8.74111 8.94412 8.88845 8.94412H9.44401V13.8886H8.3329C8.18556 13.8886 8.04425 13.9471 7.94006 14.0513C7.83588 14.1555 7.77734 14.2968 7.77734 14.4441C7.77734 14.5915 7.83588 14.7328 7.94006 14.837C8.04425 14.9411 8.18556 14.9997 8.3329 14.9997H11.6662C11.8136 14.9997 11.9549 14.9411 12.0591 14.837C12.1633 14.7328 12.2218 14.5915 12.2218 14.4441C12.2218 14.2968 12.1633 14.1555 12.0591 14.0513C11.9549 13.9471 11.8136 13.8886 11.6662 13.8886Z"
        fill={fill}
      />
      <path
        d="M10.0002 18.8891C8.24217 18.8891 6.52359 18.3678 5.06182 17.3911C3.60005 16.4143 2.46074 15.0261 1.78796 13.4019C1.11518 11.7776 0.93915 9.99036 1.28213 8.26609C1.62511 6.54181 2.47169 4.95796 3.71483 3.71483C4.95796 2.47169 6.54181 1.62511 8.26609 1.28213C9.99036 0.93915 11.7776 1.11518 13.4019 1.78796C15.0261 2.46074 16.4143 3.60005 17.3911 5.06182C18.3678 6.52359 18.8891 8.24217 18.8891 10.0002C18.8891 12.3577 17.9526 14.6186 16.2856 16.2856C14.6186 17.9526 12.3577 18.8891 10.0002 18.8891V18.8891ZM10.0002 2.22244C8.46192 2.22244 6.95817 2.6786 5.67912 3.53324C4.40007 4.38787 3.40317 5.60259 2.81449 7.02379C2.22581 8.445 2.07178 10.0088 2.37189 11.5176C2.672 13.0263 3.41276 14.4122 4.5005 15.4999C5.58824 16.5877 6.97411 17.3284 8.48285 17.6286C9.99159 17.9287 11.5554 17.7746 12.9766 17.186C14.3979 16.5973 15.6126 15.6004 16.4672 14.3213C17.3218 13.0423 17.778 11.5385 17.778 10.0002C17.778 7.93743 16.9586 5.95912 15.4999 4.5005C14.0413 3.04189 12.063 2.22244 10.0002 2.22244V2.22244Z"
        fill={fill}
      />
    </svg>
  )
}
