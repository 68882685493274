import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ direction }) => direction};
  height: 3.125rem;
  width: 100%;
  column-gap: 1.5rem;
`

export const Label = styled.label`
  font-family: "Nunito";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  text-align: center;
  color: #1f1f1f;
`

export const Track = styled.button`
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.3);
  border-radius: 50px;
  border: 0;
  width: 3.25rem;
  height: 1.875rem;
  padding: 0.125rem;
  transition: all 0.15s ease-in-out;

  &[aria-checked="false"] {
    background: #cfcfcf;

    & > span {
      margin-left: 0
    }

    & svg {
      transform: scale(0.01) rotateZ(180deg);
      opacity: 0;
      transition: all 0.15s ease-in-out;
    }
  }

  &[aria-checked="true"] {
    background: #018762;

    & > span {
      margin-left: 1.375rem;
    }

    & svg {
      transform: scale(1) rotateZ(0);
      opacity: 1;
    }
  }
`

export const Ball = styled.span`
  display: block;
  height: 1.625rem;
  width: 1.625rem;
  background: #fff;
  transition: all 0.15s ease-in-out;
  border-radius: 0.8125rem;
`
