import React from "react"
import { ProfileTag } from "../profile-tag"
import {
  Avatar,
  AvatarWrapper,
  DetailsWrapper,
  RoleAndRegistryCodeLabel,
  TagsWrapper,
  TitleAndNameLabel,
  Wrapper,
} from "./styles"
import EthnicityBlack from "../../../assets/icons/ethnicity-black.svg"
import TransIcon from "../../../assets/icons/TransIcon.svg"
import LesbianIcon from "../../../assets/icons/LesbianIcon.svg"
import Image from "../../Image"

export function ProfileCard({
    gender,
    title,
    name,
    role,
    registryCode,
    orientation,
    ethnicity,
    photo,
  }){
  return (
    <Wrapper>
      <AvatarWrapper>
        <Image imagePath={photo} imageAlt="Avatar" imageClass="img-avatar" />
      </AvatarWrapper>

      <DetailsWrapper>
        <TitleAndNameLabel>
          {title} {name}
        </TitleAndNameLabel>
        <RoleAndRegistryCodeLabel>
          {role}, <span className="code">{registryCode}</span>
        </RoleAndRegistryCodeLabel>
        <TagsWrapper>
          <ProfileTag Icon={<EthnicityBlack />} label={"Preta"} />
          <ProfileTag Icon={<Image 
                                  imagePath="TransIcon.png" 
                                  imageAlt="Ícone da bandeira Trans"
                                  imageClass="icon"
                                  />} 
                      label={"Mulher Trans"} />
          <ProfileTag Icon={<LesbianIcon />} label={"Lésbica"} />
        </TagsWrapper>
      </DetailsWrapper>
    </Wrapper>
  )
}
